.sidebar-user-container {
  display: flex;
  justify-content: left;
  align-content:flex-start;
  color: #014461; 
  margin-top: 30px;
  margin-left: 15px;
  width: 80%; 
}

.sidebar-user-container img {
  float: left;
  height: 50px;
  border-radius: 6px;
  box-shadow: 4px 3px 6px 0 rgb(0 0 0 / 20%);
}

.sidebar-user-info {
  padding-left: 15px;
  overflow-x: hidden;
}

.sidebar-user-info-hidden {
  display: none;
}

.sidebar-username {
  font-size: 20px;
  font-weight: 500;
}

.sidebar-user-role {
  font-size: 13px;
  font-weight: 400;
}
