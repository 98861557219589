.form-container {
  width: 100%;
}

form {
  width: 100%;
}

.form-input-container {
  margin-bottom: 20px;
}

input, select {
  width: 100%;
  height: 35px;
  font-size: 14px;
  border-color: transparent;
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

textarea {
  width: 100%;
  height: 70px;
  font-size: 14px;
  border-color: transparent;
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

input:focus-visible, select:focus-visible, textarea:focus-visible {
  border-color:#03a9f3;
  outline: 1px solid #03a9f3;
}

.form-checkbox-container {
  display: flex;
  align-items: center;
}

input[type='checkbox'] {
  width: 25px;
  height: 15px;
  margin: 0 10px 0 0;
}

.form-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.mui-button {
  width: 125px;
}

.editable-select-list {
  width: 95%;
}

.add-entity-container {
  box-sizing: border-box;
  min-width: 5% !important;
  max-width: 5% !important;
  padding-left: 15px;
}

.add-entity-container button {
  padding: 0;
  min-width: 30px !important;
  max-width: 30px !important;
  cursor: pointer;
  font-size: 30px;
}

@media (max-width: 768px) {

  .editable-select-list {
    width: 85%;
  }

  .add-entity-container {
    min-width: 15% !important;
    max-width: 15% !important;
  }
}