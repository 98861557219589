.outlet-container {
  position: absolute;
  left: 270px;
  width: calc(100% - 290px);
  margin-top: 70px;
  margin-left: 10px;
  margin-right: 10px;
}

.outlet-container-move-left {
  position: absolute;
  left: 90px;
  width: calc(100% - 110px);
  margin-top: 70px;
  margin-left: 10px;
  margin-right: 10px;
}

.page-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0 10px 20px;
  border-radius: 10px;
}

.page-container h4 {
  font-weight: 400;
}

.page-container h4 strong {
  font-weight: 700;
  color: #03a9f3;
}
