.empty-list {
  height: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list-header {
  display: flex;
  justify-content: space-between;
}

.mui-button {
  width: 125px;
}

.progress-container {
  margin-top: 30px;
}

.linear-progress-container {
  margin-top: 30px;
}

table {
  margin-top: 20px;
  box-sizing: border-box;
  border-collapse: collapse;
  width: 100%;
}

tbody tr:nth-child(odd) {
  background-color: #e6f6fe;
}

.goto {
  text-decoration: none;
  cursor: pointer;
  color: #0276aa;
}

.list-item {
  text-align: left;
  font-size: 15px;
  padding: 10px 10px;
}

.list-item a {
  text-decoration: none;
  color: #0276aa;
}

.list-item-right {
  text-align: right;
  font-size: 15px;
  padding: 10px 10px;
}

.list-item-center {
  text-align: center;
  font-size: 15px;
  padding: 10px 10px;
}

.negative-number {
  color: #be4d25;
}

.positive-number {
  color: black;
}

.actions {
  text-align: right;
  width: 120px;
}

.actions Button {
  padding: 0;
  min-width: 30px;
  max-width: 30px;
  width: 30px;
  cursor: pointer;
}

.actions button:disabled {
  cursor: default;
}

.name {
  width: 30%;
}

.totals-section {
  padding: 10px 0 10px;
  background-color: #e6f6fe;
  margin-top: 20px;
}

.totals-container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 3px 10px;
}

.totals-title {
  width: 150px;
}

.totals-amount {
  width: 100px;
  color: #03a9f3;
}

@media (max-width: 768px) {
  .hide {
    display: none;
  }

  .name {
    width: 50%;
  }
}
