.title {
  text-align: center;
}

.desktop-negative-number {
  color: #be4d25;
  font-size: 34px;
  font-weight: 600;
}

.desktop-positive-number {
  color: inherit;
  font-size: 34px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .title {
    font-size: 16px;
  }

  .negative-number {
    font-size: 16px;
  }
  
  .positive-number {
    font-size: 16px;
  }
}
