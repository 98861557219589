.mobile-container {
  display: none;
  flex-direction: column;
  width: 100%;
}

.mobile-widget-container {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.mobile-widget {
  display: none;
  justify-content: space-between;
  width: 100%;
  border: 2px solid;
  padding: 8px;
  border-radius: 12px;
}

.clickable-mobile-widget {
  display: none;
  justify-content: space-between;
  width: 100%;
  border: 2px solid;
  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .mobile-container {
    display: flex;
  }

  .mobile-widget {
    display: flex;
  }

  .clickable-mobile-widget {
    display: flex;
  }

  .desktop-widget {
    display: none;
  }

  .clickable-desktop-widget {
    display: none;
  }
}