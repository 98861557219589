.account-transactions-menu-container li:hover {
  background-color: #03a9f3;
  color: #fff;
}

.account-transactions-menu-container a {
  text-decoration: none;
  color: #000;
}

.account-transactions-menu-container a:hover {
  color: #fff;
}
