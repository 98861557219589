.enter-budget-item-container {
  margin-top: 20px;
}

.enter-budget-item-form-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.budget-totals-section {
  margin-top: 20px;
  padding: 10px 0 10px;
  background-color: #e6f6fe;
}

.budget-totals-container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 3px 10px;
}

.budget-totals-title {
  width: 100px;
}

.budget-totals-amount {
  width: 100px;
  color: #03a9f3;
}

.budget-income-container {
  margin-top: 40px;
}

.budget-income-container h4 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.budget-income-container table {
  margin-top: 0;
}

.budget-expenses-container {
  margin-top: 40px;
}

.budget-expenses-container h4 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.budget-expenses-container table {
  margin-top: 0;
}

.budget-list-item {
  text-align: left;
  font-size: 15px;
  padding: 10px 10px;
}

.goto a {
  text-decoration: none;
  cursor: pointer;
  color: #0276aa;
}

.amount {
  width: 200px;
}

.date {
  width: 200px;
}

.budget-list-item-center {
  text-align: center;
  font-size: 15px;
  padding: 10px 10px;
}

.budget-list-item-right {
  text-align: right;
  font-size: 15px;
  padding: 10px 10px;
}

.budget-empty-list {
  height: 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {

  .budget-list-item {
    font-size: 12px;
  }

  .budget-list-item-center {
    font-size: 12px;
  }

  .budget-list-item-right {
    font-size: 12px;
  }

  .amount {
    width: 100px;
  }
  
  .budget-actions {
    padding-left: 0;
    padding-right: 2px;
  }
}
