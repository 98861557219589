.dashboard-empty-list {
  height: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-empty-list h5 {
  font-weight: 200;
  text-align: center;
}

.overview-title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.overview-widgets-container {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
