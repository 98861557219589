hr {
  opacity: 0.25;
}

.sidebar-nav-container {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 20px;
}

.sidebar-nav-container ul {
  width: 100%;
  padding-inline-start: 0;
}

.sidebar-nav-container li {
  list-style: none;
}

.sidebar-nav-container li a {
  display: flex;
  width: 100%;
  padding-top: 3px;
  padding-left: 30px;
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-nav-container a.active {
  color: #03a9f3;
  font-weight: 600;
}

.sidebar-nav-container li:hover:not(.active) {
  background-color: #03a9f3;
  color: #fff;
}

.sidebar-nav-container a:hover:not(.active) {
  color: #fff;
}

.sidebar-nav-container a:hover:is(.active) {
  color: #fff;
}

.sidebar-nav-icon {
  color: #03a9f3;
  font-size: 24px;
}