.desktop-container {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  gap: 2rem;
}

.desktop-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 475px;
  height: 200px;
  border: 2px solid;
  padding: 8px;
  border-radius: 12px;
}

.clickable-desktop-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 475px;
  height: 200px;
  border: 2px solid;
  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .desktop-container {
    display: none;
  } 

  .desktop-widget {
    display: none;
  }

  .clickable-desktop-widget {
    display: none;
  }

  .mobile-widget {
    display: flex;
  }

  .clickable-mobile-widget {
    display: flex;
  }
}