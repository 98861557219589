.current-budget {
  text-align: left;
  font-size: 15px;
  padding: 10px 10px;
  font-weight: 900;
  background-color: rgb(219, 242, 211);
}

.current-budget-center {
  text-align: center;
  font-size: 15px;
  padding: 10px 10px;
  font-weight: 900;
  background-color: rgb(219, 242, 211);
} 

.current-budget-right {
  text-align: center;
  font-size: 15px;
  padding: 10px 10px;
  background-color: rgb(219, 242, 211);
}