.sidebar-header-container {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 265px;
  height: 60px;
  background-color: white;
}

.sidebar-header-container a {
  display: flex;
  text-decoration: none;
}

.sidebar-header-container img {
  margin-left: 20px;
}

.sidebar-header-container > a > span {
  margin-left: 15px;
  font-size: 28px;
  color: #000;
}
