.dialog-form-container {
  display: flex;
  justify-content: center;
  width: 350px;
}

.dialog-form-input-container {
  margin: 20px 20px;
}

input, select {
  width: 100%;
  height: 35px;
  font-size: 14px;
  border-color: transparent;
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.mui-button {
  width: 125px;
}

.dialog-action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px 20px;
}