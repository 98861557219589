.header-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0px;
  width: 100%;
  background-color: #03a9f3;
  color: #fff;
  height: 60px;
  box-shadow: 4px 3px 6px 0 rgb(0 0 0 / 20%);
  padding: 0 20px;
  z-index: 100;
}

.mobile-view-container {
  display: none;
  height: 60px;
}

.btn-toggle-mobile {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 35px;
  cursor: pointer;
  font-size: 20px;
}

.header-container-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-container-logo a {
  display: flex;
  text-decoration: none;
}

.header-container-logo > a > span {
  margin-left: 5px;
  font-size: 28px;
  color: #fff;
}

.btn-profile-mobile {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 35px;
  cursor: pointer;
  font-size: 20px;
}

.btn-profile-mobile svg {
  color: #fff;
}

.desktop-view-container {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
}

.desktop-header-buttons-move-left {
  position: absolute;
  left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 120px);
}

.desktop-header-buttons {
  position: absolute;
  left: 290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 310px);
}

.btn-toggle-desktop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 35px;
  cursor: pointer;
  font-size: 20px;
}

.btn-profile-desktop {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 35px;
  cursor: pointer;
  font-size: 20px;
}

.btn-profile-desktop svg {
  color: #fff;
}

.header-menu-container li:hover {
  background-color: #03a9f3;
  color: #fff;
}

.header-menu-container a {
  text-decoration: none;
  color: #000;
}

.header-menu-container a:hover {
  color: #fff;
}
