.currentBalanceLabel {
  font-size: 12px;
  margin-left: 5px;
}

.currentBalance {
  font-weight: 500;
  margin-left: 5px;
}

.invoice {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.invoice input[type=text] {
  width: 50%;
  margin-right: 10px;
}