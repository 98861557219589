$sidebar-bg-color: #fff;
$sidebar-color: #000;
$highlight-color: #03a9f3;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.layout-container {
  display: flex;
  height: 100%;
  width: 100%;

  @media (max-width: $breakpoint-md) {
    .mobile-view-container {
      display: flex;
    }

    .desktop-view-container {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .outlet-container, .outlet-container-move-left {
      position: absolute;
      left: 0;
      width: calc(100% - 20px);
      margin-top: 70px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
