.auth-container {
  display: flex;
  height: 100vh;
  background-color: #fff;
}

.auth-action-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 33.3333333%;
  height: 100vh;
}

.auth-action-section {
  width: 80%;
  margin: auto;
  height: 800px;
}

.auth-action-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  height: 200px;
}

.auth-action-logo  img {
  width: 90px;
}

.auth-action-logo  span {
  margin-left: 10px;
}

.auth-action-title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
}

.auth-action-subtitle {
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
  color: #919aa3;
}

.progress-container {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.form-container {
  margin-top: 40px;
}

.auth-action-button {
  width: 100%;
}

.forgot-password {
  margin-top: 40px;
  text-align: end;
  font-size: 13px;
  color: #919aa3;
}

.forgot-password a {
  text-decoration: none;
  color: #03a9f3;
}

.forgot-password a:visited {
  color: #03a9f3;
}

.sign-in {
  margin-top: 40px;
  text-align: center;
  font-size: 13px;
  color: #919aa3;
}

.sign-up {
  margin-top: 40px;
  text-align: center;
  font-size: 13px;
  color: #919aa3;
}

.sign-in a {
  text-decoration: none;
  color: #03a9f3;
}

.sign-up a {
  text-decoration: none;
  color: #03a9f3;
}

.sign-in a:visited {
  color: #03a9f3;
}

.sign-up a:visited {
  color: #03a9f3;
}

.auth-background-container {
  flex: 66.66666666%;
  height: 100vh;
  background-image: url('../../assets/images/auth-bg.jpg');
  background-size: cover;
  background-position: 50%;
}

@media (max-width: 1024px) {
  .auth-background-container {
      display:none
  }
}

