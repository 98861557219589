.month-container {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap: 2rem;
}

.month-totals-section {
  border: 2px solid;
  border-color: #03a9f3;
  background-color: #fafdff;
  padding: 8px;
  border-radius: 12px;
  width: 475px;
  cursor: pointer;
}

.month-totals-section h3 {
  margin-left: 10px;
}

.month-totals-section hr {
  width: 100%;
  border-color: #03a9f3;
}

.month-totals-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 10px;
}

.month-totals-title {
  width: 230px;
}

.month-totals-subtitle {
  margin-left: 30px;
  width: 200px;
}

.month-totals-amount {
  width: 100px;
  text-align: right;
  color: #03a9f3;
}

@media (max-width: 768px) {
  .month-container {
    display: inline-flex;
    width: 100%;
  }

  .month-totals-section {
    width: 95%;
  }
}
